import React from 'react'
import cn from 'classnames'

// Style
import style from './CommentSection.module.scss'

// Utils
import { timer } from '@utils/async'
import { addURLProtocol } from '@utils/string'
import { encode } from '@utils/encode'
import { upperFirst } from 'lodash'

// Components
import { Location } from '@reach/router'
import { Col, Row, FormGroup, Input } from 'reactstrap'
import Comment from '../Comment'
import Button from '../Button'

const STATES = {
  comment: 'comment',
  success: 'success',
  error: 'error',
}

class CommentSection extends React.Component {
  state = {
    commentForm: {},
    comments: [...this.props.comments],
    step: STATES.comment,
    repliedTo: 'none',
  }

  render() {
    const { comments, step, repliedTo } = this.state
    const commentsCount = comments.length

    const formClasses = cn({
      [style[`state${upperFirst(step)}`]]: step,
    })

    return (
      <Row className="my-5">
        {commentsCount ? (
          <>
            <Col md="7">
              <h5 className="mb-3 u-text--detail">
                {commentsCount} Comment{commentsCount > 1 ? 'i' : 'o'}
              </h5>
              <div>
                {comments.map(
                  ({ id, name, message, date, web, replies }, index) => {
                    const link = addURLProtocol(web)
                    const comment = [
                      <Comment
                        key={index}
                        name={name}
                        date={date}
                        message={message}
                        linkedin={link}
                        onReplyClick={this.setRepliedTo(id)}
                      />,
                    ]
                    if (Array.isArray(replies)) {
                      const reps = replies.forEach(
                        ({ name, message, date, web }, i) => {
                          const linkReply = addURLProtocol(web)
                          comment.push(
                            <Comment
                              key={`rep-${index}-${i}`}
                              type="reply"
                              name={name}
                              date={date}
                              message={message}
                              linkedin={linkReply}
                            />,
                          )
                        },
                      )
                    }
                    return comment
                  },
                )}
              </div>
            </Col>
            <Col md="5">
              <div className={style.commentForm}>
                <h5 className="mb-3 u-text--detail">Lascia il tuo commento</h5>
                {this.state.repliedTo !== 'none' && (
                  <p className="u-text--info">
                    Stai rispondendo a: {this.getCommentNameById(repliedTo)} (
                    <button
                      type="button"
                      className={style.discard}
                      onClick={this.setRepliedTo('none')}
                    >
                      Annulla
                    </button>
                    )
                  </p>
                )}
                <Location>
                  {({ location }) => (
                    <div className={formClasses}>
                      <form
                        className={style.comment}
                        name="comments-queue"
                        method="post"
                        action="/thanks"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit={this.handleSubmit}
                      >
                        <input
                          name="path"
                          type="hidden"
                          ref={c => (this.inputPath = c)}
                          value={location.pathname}
                        />
                        <input
                          name="article"
                          type="hidden"
                          value={this.props.title}
                        />
                        <input
                          type="hidden"
                          name="comment-replied-to"
                          value={repliedTo}
                        />
                        <FormGroup>
                          <Input
                            type="textarea"
                            name="comment-message"
                            id="comment-message"
                            placeholder="Scrivi il tuo commento"
                            onChange={this.handleChange}
                            required
                          />
                        </FormGroup>
                        <FormGroup>
                          <Input
                            type="text"
                            name="comment-author"
                            id="comment-author"
                            placeholder="Il tuo nome"
                            onChange={this.handleChange}
                            required
                          />
                        </FormGroup>
                        <FormGroup>
                          <Input
                            type="text"
                            name="comment-linkedin"
                            id="comment-linkedin"
                            placeholder="Il tuo profilo di linkedin"
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                        <p className="text-right">
                          <Button
                            label="Iscriviti"
                            type="submit"
                            modifier="primary"
                          >
                            Invia
                          </Button>
                        </p>
                      </form>
                      <div className={style.success}>
                        <h4 className="u-text--primary">Grazie!</h4>
                        <p className="u-text--intro">
                          Il tuo commento sarà visibile a tutti tra qualche
                          minuto.
                        </p>
                      </div>
                      <div className={style.error}>
                        <h4 className="u-text--primary">Ouch...</h4>
                        <p className="u-text--intro">
                          Qualcosa è andato storto. Per favore segnala il
                          malfunzionamento a{' '}
                          <a href="mailto:annagirardi@mail.com">
                            annagirardi@mail.com
                          </a>
                        </p>
                      </div>
                    </div>
                  )}
                </Location>
              </div>
            </Col>
          </>
        ) : (
          <Col md={{ size: 7, offset: 1 }}>
            <h5 className="mb-3 u-text--detail">Lascia il tuo commento</h5>
            {this.state.repliedTo !== 'none' && (
              <p className="u-text--info">
                Stai rispondendo a: {this.getCommentNameById(repliedTo)} (
                <button
                  type="button"
                  className={style.discard}
                  onClick={this.setRepliedTo('none')}
                >
                  Annulla
                </button>
                )
              </p>
            )}
            <Location>
              {({ location }) => (
                <div className={formClasses}>
                  <form
                    className={style.comment}
                    name="comments-queue"
                    method="post"
                    action="/thanks"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    <input
                      name="path"
                      type="hidden"
                      ref={c => (this.inputPath = c)}
                      value={location.pathname}
                    />
                    <input
                      name="article"
                      type="hidden"
                      value={this.props.title}
                    />
                    <input
                      type="hidden"
                      name="comment-replied-to"
                      value={repliedTo}
                    />
                    <FormGroup>
                      <Input
                        type="textarea"
                        name="comment-message"
                        id="comment-message"
                        placeholder="Scrivi il tuo commento"
                        onChange={this.handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="text"
                        name="comment-author"
                        id="comment-author"
                        placeholder="Il tuo nome"
                        onChange={this.handleChange}
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Input
                        type="text"
                        name="comment-linkedin"
                        id="comment-linkedin"
                        placeholder="Il tuo profilo di linkedin"
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    <p className="text-right">
                      <Button
                        label="Iscriviti"
                        type="submit"
                        modifier="primary"
                      >
                        Invia
                      </Button>
                    </p>
                  </form>
                  <div className={style.success}>
                    <h4 className="u-text--primary">Congratulazioni!</h4>
                    <p className="u-text--intro">Hai postato un commento</p>
                  </div>
                  <div className={style.error}>
                    <h4 className="u-text--primary">Ouch...</h4>
                    <p className="u-text--intro">
                      Qualcosa è andato storto. Per favore segnala il
                      malfunzionamento a{' '}
                      <a href="mailto:annagirardi@mail.com">
                        annagirardi@mail.com
                      </a>
                    </p>
                  </div>
                </div>
              )}
            </Location>
          </Col>
        )}
      </Row>
    )
  }

  handleChange = e => {
    this.setState({
      commentForm: {
        ...this.state.commentForm,
        [e.target.name]: e.target.value,
      },
    })
  }

  handleSubmit = e => {
    const formEl = e.currentTarget
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        path: this.inputPath.value,
        article: this.props.title,
        'comment-replied-to': this.state.repliedTo,
        ...this.state.commentForm,
      }),
    })
      .then(() => {
        const {
          commentForm: {
            'comment-author': name,
            'comment-message': message,
            'comment-linkedin': web,
          },
          repliedTo,
          comments,
        } = this.state
        const date = new Date()

        if (repliedTo !== 'none') {
          const commentIndex = comments.findIndex(c => c.id === repliedTo)
          if (commentIndex > -1) {
            const newComments = [...comments]
            newComments[commentIndex].replies = [
              ...newComments[commentIndex].replies,
              {
                name,
                message,
                web,
                date,
              },
            ]
            this.setState({
              step: STATES.success,
              comments: newComments,
              repliedTo: 'none',
            })
          }
        } else {
          this.setState({
            step: STATES.success,
            comments: [
              ...comments,
              {
                date,
                id: date,
                message,
                name,
                web,
                repliedTo: 'none',
                replies: [],
              },
            ],
            repliedTo: 'none',
          })
        }
        return timer(5000)
      })
      .then(() => {
        // Reset form fields
        this.resetFormFields(formEl)
        // Show form again
        this.setState({
          commentForm: {},
          step: STATES.comment,
        })
      })
      .catch(e => {
        console.log(e)
        this.setState({ step: STATES.error })
        timer(5000).then(() => {
          this.setState({ step: STATES.comment })
        })
      })
  }

  resetFormFields = formEl => {
    formEl.reset()
  }

  setRepliedTo = val => () => {
    this.setState({
      repliedTo: val,
    })
  }

  setPostComments = comments => {
    if (typeof window !== 'undefined') {
      const postComments = comments.filter(
        comment => comment.data.path === window.location.pathname,
      )

      this.setState({
        comments: postComments,
      })
    }
  }

  getCommentNameById = id => {
    const { comments } = this.state
    const comment = comments.find(c => c.id === id)
    if (typeof comment === 'object' && comment.name) {
      return comment.name
    } else {
      return 'Anonimo'
    }
  }
}

export default CommentSection
