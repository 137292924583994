export const addURLProtocol = url => {
  if (typeof url !== 'string' || url.length === 0) {
    return
  }
  const r = new RegExp('^(?:[a-z]+:)?//', 'i')
  if (r.test(url)) {
    return url
  } else if (url.startsWith('/')) {
    return `https:/${url}`
  } else {
    return `https://${url}`
  }
}
