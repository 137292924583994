import React, { useRef } from 'react'
import PropTypes from 'prop-types'

// Utils
import { kebabCase, upperFirst } from 'lodash'

// Style
import style from './ArticleWordsBox.module.scss'

// Components
import { Link } from 'gatsby'
import TagList from '@components/TagList'

// assets
import thinking from '@assets/images/thinking.png'
import focus from '@assets/images/focus.png'
import connections from '@assets/images/connections.png'
import teaching from '@assets/images/teaching.png'

const images = [thinking, focus, connections, teaching]

const ArticleWordsBox = ({ themes, words }) => {
  const src = useRef(images[Math.floor(Math.random() * 4) + 0])
  const cleanThemes = Array.isArray(themes)
    ? themes.filter(t => t.name).map(t => t.name)
    : []
  const themeNames = useRef(cleanThemes)
  return (
    <div className={style.root}>
      {Array.isArray(themeNames.current) && !!themeNames.current.length && (
        <div className={style.column}>
          <h3 className="u-text--detail">I temi</h3>
          <TagList tags={themeNames.current} folder="tema" />
        </div>
      )}
      {Array.isArray(words) && !!words.length && (
        <div className={style.column}>
          <h3 className="u-text--detail">Le parole</h3>
          <TagList tags={words} folder="parola" />
        </div>
      )}
    </div>
  )
}

ArticleWordsBox.propTypes = {
  themes: PropTypes.array,
  words: PropTypes.array,
}

export default ArticleWordsBox
