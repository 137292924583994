import React from 'react'
import { kebabCase, upperFirst } from 'lodash'
import { Link } from 'gatsby'

const TagList = ({ tags, folder }) =>
  tags ? (
    <div>
      {tags.map((tag, index) => (
        <Link
          key={tag}
          className="mr-2 u-text--nowrap u-flow--inline-block"
          to={`/${folder}/${kebabCase(tag)}/`}
        >
          {`${upperFirst(tag)}${index === tags.length - 1 ? '' : ','}`}
        </Link>
      ))}
    </div>
  ) : null

export default TagList
