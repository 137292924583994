// the name 'submission-created' indicates the event that trigger the function
// -> https://docs.netlify.com/functions/trigger-on-events/#available-triggers
import React, { useRef } from 'react'
import PropTypes from 'prop-types'

// Utils
import { getThemesByWords } from '@utils/themes'

// Components
import { Link, graphql } from 'gatsby'
import TagList from '@components/TagList'
import Author from '@components/Author'
import ArticleInfo from '@components/ArticleInfo'
import ArticleWordsBox from '@components/ArticleWordsBox'
import CommentSection from '@components/CommentSection'
import Content, { HTMLContent } from '@components/Content'
import SEO from '@components/SEO'
import Big from '@components/Big'
import { Col, Container, Row } from 'reactstrap'
import {
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share'

import logoSrc from '../../assets/favicon.png'

export const BlogPostTemplate = ({
  author,
  commentsLength,
  content,
  contentComponent,
  date,
  description,
  themes = [],
  title,
  url,
  words,
}) => {
  const PostContent = contentComponent || Content
  const shareUrl = `https://www.paroleallavoro.it${url}`

  return (
    <main>
      <article>
        <Row>
          <Col xs="12">
            <header className="mt-5">
              <h1>{title}</h1>
              <div className="u-text--intro">
                <ArticleInfo date={date} commentsLength={commentsLength} />{' '}
                <Big
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </div>
            </header>
          </Col>
          <Col className="mt-3" md={{ size: 9 }}>
            <ArticleWordsBox themes={themes} words={words} />
            <Row>
              <Col md={{ size: 10 }}>
                <PostContent content={content} />
                <div className="d-md-none mt-5">
                  {author && author.frontmatter && (
                    <>
                      <strong className="d-block mt-3 mb-5">
                        <em>{author.frontmatter.title}</em>
                      </strong>
                      <Author {...author.frontmatter} />
                    </>
                  )}
                </div>
                {author && author.frontmatter && (
                  <strong className="d-none d-md-block mt-3 mb-5">
                    <em>{author.frontmatter.title}</em>
                  </strong>
                )}
                <h3 className="mt-5 u-text--detail">Condividi</h3>
                <LinkedinShareButton url={shareUrl}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>{' '}
                <TwitterShareButton url={shareUrl}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>{' '}
                <TelegramShareButton url={shareUrl}>
                  <TelegramIcon size={32} round />
                </TelegramShareButton>{' '}
                <WhatsappShareButton url={shareUrl}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>{' '}
                <EmailShareButton url={shareUrl}>
                  <EmailIcon size={32} round />
                </EmailShareButton>{' '}
              </Col>
            </Row>
          </Col>
          <Col className="d-none d-md-block mt-3" md={{ size: 3 }}>
            {author && author.frontmatter && <Author {...author.frontmatter} />}
          </Col>
        </Row>
      </article>
    </main>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
}

const BlogPost = ({
  data: { markdownRemark: post, allCommentsJson },
  pageContext: { themes, comments, slug },
}) => {
  const { author, description } = post.fields
  const { id, html, frontmatter } = post
  const { date, title, words, seodescription, seoimage } = frontmatter

  const postWords = useRef(
    Array.isArray(words) ? words.map((w) => w.label) : [],
  )
  const postThemes = useRef(getThemesByWords(themes, postWords.current))
  const commentsLength = comments.length
  const shareUrl = `https://www.paroleallavoro.it${slug}`

  const meta = []

  if (seoimage) {
    meta.push({
      property: `og:image`,
      content: `https://www.paroleallavoro.it${seoimage}`,
    })
  }

  if (slug) {
    meta.push({
      property: `og:url`,
      content: shareUrl,
    })
  }

  if (author?.frontmatter?.title) {
    meta.push({
      property: `author`,
      content: author.frontmatter.title,
    })
  }

  return (
    <>
      <SEO
        title={title}
        description={seodescription || description}
        meta={meta}
      />
      <Container>
        <Row>
          <Col md={{ size: 11, offset: 1 }}>
            <BlogPostTemplate
              content={html}
              contentComponent={HTMLContent}
              description={description}
              words={postWords.current}
              themes={postThemes.current}
              title={title}
              date={date}
              url={slug}
              author={author}
              commentsLength={commentsLength}
            />
          </Col>
        </Row>
        <CommentSection comments={comments} title={title} />
      </Container>
    </>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const BlogPostQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date
        title
        words {
          value
          label
        }
        seoimage
        seodescription
      }
      fields {
        description
        author {
          id
          frontmatter {
            title
            photo
            twitter
            linkedin
            description
          }
        }
      }
    }
  }
`
