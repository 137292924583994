import React from 'react'
import cn from 'classnames'

// Components
import { Link } from 'gatsby'

// Style
import style from './Author.module.scss'

// assets
// import anna from '@assets/images/anna-pic.png'

const Author = ({ photo, title, linkedin, twitter, description }) => (
  <div className={style.root}>
    <figure>
      <img className={style.photo} src={photo} alt={title} />
      {/* <img className={style.photo} src={anna} alt={title} /> */}
    </figure>
    <div>
      <small className={style.description}>{description}</small>
    </div>
  </div>
)

export default Author
