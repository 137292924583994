import React from 'react'
import CommentInfo from '../CommentInfo'
import cn from 'classnames'

// Style
import style from './Comment.module.scss'

const Comment = ({ name, date, message, linkedin, type, onReplyClick }) => (
  <article className={cn(style.root, { [style[`type${type}`]]: type })}>
    <header>
      <div className={style.name}>
        {linkedin ? (
          <a target="_blank" rel="noopener noreferrer" href={linkedin}>
            {name}
          </a>
        ) : (
          <span>{name}</span>
        )}{' '}
        - <CommentInfo date={date} />
      </div>
    </header>
    <div className={style.body}>{message}</div>
    <footer className={style.footer}>
      <div />
      {typeof onReplyClick === 'function' && (
        <button type="button" className={style.reply} onClick={onReplyClick}>
          Rispondi &rarr;
        </button>
      )}
    </footer>
  </article>
)

export default Comment
