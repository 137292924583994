import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

// Style
import style from './Big.module.scss'

const Big = ({ children, ...props }) => {
  return (
    <div className={style.root} {...props}>
      {children}
    </div>
  )
}

Big.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default Big
