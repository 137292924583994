import React from 'react'
import PropTypes from 'prop-types'

// Styles
import style from './Content.module.scss'

export const HTMLContent = ({ content, className }) => (
  <div className={style.root} dangerouslySetInnerHTML={{ __html: content }} />
)

const Content = ({ content }) => <div className={style.root}>{content}</div>

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
