export const timer = time => new Promise(resolve => setTimeout(resolve, time));

export const nextFrame = () =>
  new Promise(resolve => {
    if (typeof window === 'object') {
      window.requestAnimationFrame(resolve);
    } else {
      resolve();
    }
  });
