export const getThemeByWord = (themes, word) => {
  return themes.find(t => t.words.includes(word));
};

export const getThemesByWords = (themes, words) => {
  const distinctThemes = [];
  words.forEach(w => {
    const t = getThemeByWord(themes, w);
    if (t && !distinctThemes.includes(t)) {
      distinctThemes.push(t);
    }
  });
  return distinctThemes;
};
