import React from 'react'

// Style
import style from './Button.module.scss'

const Button = ({ children, type = 'button', block, modifier, onClick }) => {
  let classes = style.root
  if (block) {
    classes += ` ${style.block}`
  }
  // modifier
  if (modifier && style[modifier]) {
    classes += ` ${style[modifier]}`
  }
  return (
    <button type={type} onClick={onClick} className={classes}>
      {children}
    </button>
  )
}

export default Button
